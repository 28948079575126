import React, { useEffect, useState } from 'react'
import '../Assets/style/style.css'
import logo from '../Assets/images/HexRentals-Logo.png'
import translate from '../Assets/images/google-translate.png'
import $ from 'jquery'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'

const Navbar = () => {
  const navigate = useNavigate();
  const { REACT_APP_SERVER_URL } = process.env;
  // const [token,setToken]= useState('')
  const name = localStorage.getItem('name')
  const token = localStorage.getItem('token')
  const cus_ID = localStorage.getItem("cus_ID");
  useEffect(() => {
    
  // setToken(localStorage.getItem('token'))
    
  }, [])
  
  
  $(window).on("scroll", function () {
    if ($(window).scrollTop() > 100) {
      $(".hedear-top").addClass("active");
    } else {
      //remove the background property so it comes transparent again (defined in your css)
      $(".hedear-top").removeClass("active");
    }
  });
  const handleLogout=(e)=>{ 
    e.preventDefault()  
    window.localStorage.removeItem('token');
    window.localStorage.removeItem('cus_ID');
    window.localStorage.removeItem('name');
    navigate('/')
    axios({
      url:`${REACT_APP_SERVER_URL}/api/customer/logout?customer_id=${cus_ID}`,
      method:'GET',
      headers: {
          'Content-Type': 'application/json',
          "Authorization":`Bearer ${token}`,
      },
    }).then((res)=>{
      console.log(res.data);
    }).catch((err) => {
      if(err.request){ console.log(err.request) } if(err.response){ 
          console.log(err.response)
      }})
  }
  return (
    <div>
        <header className="hedear-top">
    <div className="container">
        <div className="total-banner">
        
        <nav className="navbar navbar-expand-lg navbar-light">
            <a className="navbar-brand" href="#">
                <img className="hex-rental-logo" src={logo} alt=""/></a>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
          
            <div className="collapse navbar-collapse nav-menus nav-responsive" id="navbarSupportedContent">
              <ul className="navbar-nav">
                <li className="nav-item active">
                  <a className="nav-link nav-category hvr-underline-from-center" href="#">HOME<span className="sr-only">(current)</span></a>
                </li>
                <li className="nav-item active">
                    <a className="nav-link nav-category hvr-underline-from-center" href="#">ABOUT US <span className="sr-only">(current)</span></a>
                  </li>
                  <li className="nav-item active">
                    <Link className="nav-link nav-category hvr-underline-from-center" to={'/carlisting'}>CAR RENTAL <span className="sr-only">(current)</span></Link>
                  </li>
                  <li className="nav-item active">
                    <Link className="nav-link nav-category hvr-underline-from-center" >SERVICES <span className="sr-only">(current)</span></Link>
                  </li>
                  <li className="nav-item active">
                    <a className="nav-link nav-category hvr-underline-from-center" href="#">CAREERS<span className="sr-only">(current)</span></a>
                  </li>
                  <li className="nav-item active">
                    <a className="nav-link nav-category hvr-underline-from-center" href="#">GALLERY<span className="sr-only">(current)</span></a>
                  </li>
                  <li className="nav-item active">
                    <a className="nav-link nav-category hvr-underline-from-center" href="#">CONTACT US<span className="sr-only">(current)</span></a>
                  </li>
                  <div className="rental-nav-btn">
                  
                   {token?
                  <li className="nav-item dropdown hex-login">
                  <a className="nav-link dropdown-toggle hex-login-dropdown" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    {name}
                  </a>
                  <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                    <Link className="dropdown-item" to={'/profile'}>Profile</Link>
                    <Link className="dropdown-item" onClick={handleLogout}>Logout</Link>
                    
                    {/* <div className="dropdown-divider"></div>
                    <a className="dropdown-item" href="#">Something else here</a> */}
                  </div>
                </li>
                   :
                <li className="nav-item dropdown hex-login">
                  <a className="nav-link dropdown-toggle hex-login-dropdown" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    LOGIN/REGISTRATION
                  </a>
                  <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                    <Link className="dropdown-item" to={'/login'}>Login</Link>
                    <Link className="dropdown-item" to={'/register'}>Register</Link>
                    {/* <div className="dropdown-divider"></div>
                    <a className="dropdown-item" href="#">Something else here</a> */}
                  </div>
                </li>
           }
          
                <div className="language">
                <li className="nav-item dropdown hex-login ar-btn">
                    <a className="nav-link dropdown-toggle hex-login-dropdown" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <img src={translate} className="img-fluid" alt=""/>
                      AR
                    </a>
                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                      <a className="dropdown-item" href="#">Action</a>
                      <a className="dropdown-item" href="#">Another action</a>
                     
                      
                    </div>
                  </li>
                </div>
            </div>
    
              </ul>
         
            </div>
          </nav>
        </div>
        </div>
    </header>
    </div>
  )
}

export default Navbar
